<template>
    <el-main>
        <el-form>
            <el-form-item>
                <el-input style="margin-top: 20px" v-model="email" />
                <el-button :disabled="loading" style="margin-top: 20px;" @click="deleteEmail">{{ loading ? 'Удаление ...' : 'Удалить пользователя из базы' }}</el-button>
            </el-form-item>
        </el-form>
    </el-main>
</template>

<script>
export default {
    data() {
        return {
            email: "",
            loading: false,
        };
    },

    methods: {
        async deleteEmail() {
            try {
                this.loading = true;
                await this.$Api.Main.deleteEmail(this.email);

                this.email = "";
            } catch (error) {
                alert(error.message || error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
