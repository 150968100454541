var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticStyle: { "margin-top": "20px" },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { disabled: _vm.loading },
                  on: { click: _vm.deleteEmail },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.loading
                        ? "Удаление ..."
                        : "Удалить пользователя из базы"
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }